@import 'variables.scss';

@mixin flex-display($display:flex) {
    display: -webkit-$display;
    display: -ms-flexbox;
    display: $display;
}

@mixin flex-direction($direction: row) {
  -webkit-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

@mixin flex-order($order:0) {
    -webkit-order: $order;
   -ms-flex-order: $order;
            order: $order;
}

@mixin flex-grow($grow:0) {
  -webkit-flex-grow: $grow;
      -ms-flex-grow: $grow;
          flex-grow: $grow;
}

@mixin flex-shrink($shrink:1) {
  -webkit-flex-shrink: $shrink;
      -ms-flex-shrink: $shrink;
          flex-shrink: $shrink;
}

@mixin flex-basis($width:auto) {
  -webkit-flex-basis: $width;
      -ms-flex-basis: $width;
          flex-basis: $width;
}

@mixin justify-content($justify:flex-start) {
  -webkit-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
}

@mixin align-content($align:stretch) {
  -webkit-align-content: $align;
      -ms-align-content: $align;
          align-content: $align;
}

@mixin align-items($align:stretch) {
  -webkit-align-items: $align;
      -ms-align-items: $align;
          align-items: $align;
}

@mixin align-self($align:auto) {
  -webkit-align-self: $align;
      -ms-align-self: $align;
          align-self: $align;
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: $line-height-base, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -0.5em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 100%;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
// --* Loading animation mixins: Starts *--//
@mixin loaderAnimation($time) {
  -moz-animation-delay:$time;
  -webkit-animation-delay:$time;
  -ms-animation-delay:$time;
  animation-delay:$time;
}
@mixin circleProperties($radius, $name, $duration, $iteration, $direction) {
  -moz-animation:$name $duration $iteration $direction;
  -webkit-animation:$name $duration $iteration $direction;
  -ms-animation:$name $duration $iteration $direction;
  animation:$name $duration $iteration $direction;
  border-radius:$radius;
}


@-moz-keyframes circular_rotate{
  100%{ -moz-transform:scale(1) }
  0%{ -moz-transform:scale(.5) }
}
@-webkit-keyframes circular_rotate{
  100%{ -webkit-transform:scale(1) }
  0%{ -webkit-transform:scale(.5) }
}
@-ms-keyframes circular_rotate{
  100%{ -ms-transform:scale(1) }
  0%{ -ms-transform:scale(.5) }
}
@keyframes circular_rotate{
  100%{ transform:scale(1) }
  0%{ transform:scale(.5) }
}
// --* Loading animation mixins: Ends *--//

@mixin respond-to($media) {
  @if $media == xsmall {
    @media only screen and (min-width: $xsmall-breakpoint) {
      @content;
    }
  }

  @else if $media == msmall {
    @media only screen and (min-width: $msmall-breakpoint) {
      @content;
    }
  }

  @else if $media == small {
    @media only screen and (min-width: $small-breakpoint) {
      @content;
    }
  }

  @else if $media == medium {
    @media only screen and (min-width: $medium-breakpoint) {
      @content;
    }
  }

  @else if $media == large {
    @media only screen and (min-width: $large-breakpoint) {
      @content;
    }
  }

  @else if $media == xlarge {
    @media only screen and (min-width: $xlarge-breakpoint) {
      @content;
    }
  }

  @else if $media == xxlarge {
    @media only screen and (min-width: $xxlarge-breakpoint) {
      @content;
    }
  }
}

@mixin set-text-as($size, $weight:medium) {
  // identity ui html font-size: 10px. New font size and line-height to be calculated wrt 10px from 14px
  @if $size == small {
    // original 0.9, 1.4
    font-size: 1.26rem;
    line-height: 1.4;
  }

  @else if $size == medium {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  @else if $size == large {
    // original 1.5, 1.5
    font-size: 2.1rem;
    line-height: 1.5;
  }

  @else if $size == xlarge {
    font-size: 2rem;
    line-height: 1.5;
  }

  @else if $size == xxlarge {
    font-size: 2.2rem;
    line-height: 1.2;
  }

  // weight

  @if $weight == medium {
    font-weight: 400;
  }

  @else if $weight == semibold {
    font-weight: 600;
  }

  @else if $weight == bold {
    font-weight: 700;
  }

  @else if $weight == black {
    font-weight: 800;
  }
}