/*
 * styles.scss
 *
 * Master global styles for import from .angular-cli.json.
 */

/*
 * GOOGLE FONTS
 * TODO: Figure out how to make this work. Then we can remove `<link>` tags in index.html.
 */
/* @import url(https://fonts.googleapis.com/icon?family=Material+Icons); */
/* @import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,400i,700,700i); */
/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&subset=latin-ext); */

/*
 * IDENTITY UI STYLES
 *
 * Variables declared here with `!default` will take precedence.
 */
@import "mixins";
@import "../src/assets/font-icons/font-icons";

/*
 * COMMON STYLES
 */
 :focus {
    outline: 2px solid #2f8367 !important;
    outline-offset: 2px !important;
  }

  .links {
    font-size: 14px;
    color: $teal-blue !important;
    font-weight: 600;
  }

  .btn {
    margin: 0px;
    margin-top: 20px;

    &.btn-outline {
      min-width: 112px;
      border: 1px solid $teal-blue;
      background-color: transparent;
      color: $teal-blue;
      font-size: 14px;
      @media (max-width:767px) {
        font-size: 12px;
        min-width: 90px;
        }
        &:focus{
                  color: #ffffff !important;
                  text-decoration: none !important;
                  background-color: $teal-blue !important;
                }
       &:hover{
                  color: #ffffff !important;
                  text-decoration: none !important;
                  background-color: $teal-blue !important;
                }
    }


    &.btn-primary {
      min-width: 112px;
      color: #ffffff !important;
      background-color: $teal-blue;
      font-size: 14px;
      border: 1px solid $teal-blue;
      border-radius: 4px;
      letter-spacing: 0.3px;
      @media (max-width:767px) {
        font-size: 12px;
        min-width: 90px;
        }
    }
  }

/*****************************************************************
 * BOOTSTRAP MATERIAL DESIGN
 *
 * Are we using this?
 * Do we need ripples?
 */
@import "bootstrap-material-design/sass/bootstrap-material-design.scss";
/* @import "bootstrap-material-design/dist/css/ripples.css"; */

/*****************************************************************
 * BOOTSTRAP
 */

// Reset and dependencies
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "bootstrap-sass/assets/stylesheets/bootstrap/print";
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/glyphicons"; */

// Core CSS
@import "bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/dropdowns"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/button-groups"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/input-groups"; */
@import "bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "bootstrap-sass/assets/stylesheets/bootstrap/navbar";
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/pagination"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/pager"; */
@import "bootstrap-sass/assets/stylesheets/bootstrap/labels";
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/badges"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/jumbotron"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/thumbnails"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/alerts"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/progress-bars"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/media"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/list-group"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/panels"; */
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed"; */

/* Seems to be used in dashboard. */
@import "bootstrap-sass/assets/stylesheets/bootstrap/wells";

/* @import "bootstrap-sass/assets/stylesheets/bootstrap/close"; */

// Components w/ JavaScript
@import "bootstrap-sass/assets/stylesheets/bootstrap/modals";
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/tooltip"; */
@import "bootstrap-sass/assets/stylesheets/bootstrap/popovers";
/* @import "bootstrap-sass/assets/stylesheets/bootstrap/carousel"; */

// Utility classes
@import "bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "bootstrap-sass/assets/stylesheets/bootstrap//responsive-utilities";

/*
 * ANGULAR MATERIAL THEME
 * We are not using the theme per se, but we are using Angular material styles.
 */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

/*
 * COMMON
 *
 * Common application styles from @tandfgroup/pyramid-common.
 */
 i:hover,
 i:focus{
   text-decoration:none;
 }

 a:focus {
   color:$brand-primary;
 }


main {
  @include flex-display($display: flex);
  min-height: calc(100vh - 175px);
  p, span {
    font-size: 14px;
  }
}

 .droid-serif-font {
   font-family: $font-family-serif;
 }

 .open-sans-font {
   font-family: $font-family-sans-serif;
 }

 .font-s10 {
   font-size: 10px;
 }

 .font-s12 {
   font-size: 12px;
 }

 .font-s13 {
   font-size: 13px;
 }

 .font-s15 {
   font-size: 15px;
 }

 .font-s18 {
   font-size: 18px;
 }

 .font-s22 {
   font-size: 22px;
 }

 .font-s24 {
   font-size: 24px;
 }

 .font-s42 {
   font-size: 42px;
 }

 .font-style-italic {
   font-style: italic;
 }

 .font-weight-normal {
   font-weight: normal;
 }

 .font-weight-bold {
   font-weight: bold;
 }

 .text-disable-color {
   color:$input-border;
 }

 .text-uppercase {
   text-transform: uppercase;
 }

 .container {
   @media (min-width: $screen-xs-min) and (max-width:$screen-xs-max) {
     padding-left:15px;
     padding-right:15px;
   }
   @media (min-width: $screen-sm-min) and (max-width:$screen-sm-max){
     padding-left:25px;
     padding-right:25px;
   }
   @media (min-width: $screen-md-min) and (max-width:$screen-md-max){
     padding-left:52px;
     padding-right:53px;
   }
   @media (min-width: $screen-lg-min){
     padding-left:65px;
     padding-right:65px;
   }
 }

 footer {
   font-size: 12px;
   p {
     margin-top:15px;
   }
 }

 // global class names for no-spacings
 .no-padding {
   padding: 0 !important;
 }
 .no-margin {
   margin: 0 !important;
 }

 .hide-text {
   line-height: 0;
   font-size: 0;
   color: transparent;
 }

 .form-group {
   label {
     &.control-label {
       font-size: $mdb-input-font-size-base;
     }
   }

   // tnf toolkit styles for Form inputs
   input.form-control {
     background-image: none;
     border: 1px solid $input-border;
     height: 46px;
     font-size: $mdb-input-font-size-base;
     padding: 10px;
     margin-bottom: 1px;
     border-radius: 5px;
     &.input-small {
       width: 284px;
     }
     &.input-large {
       width: 484px;
     }
     &[disabled] {
       border: 1px solid $input-border;
       opacity: 0.5;
     }
   }
   &.is-focused {
     input.form-control,
     select.form-control {
       background-image: none;
       border: 1px solid $input-border-focus;
     }
   }
   &.has-error {
     input.form-control {
       background-image: none;
       border: 1px solid $state-danger-text;
       box-shadow: none;
     }
     .help-support {
       color: $state-danger-text;
     }
     &.is-focused {
       input.form-control {
         background-image: none;
         border: 1px solid $state-danger-text;
       }
       .help-support {
         color: $state-danger-text;
       }
     }
   }
   &.label-floating {
     label.control-label {
       top: -3px;
       left: 15px;
       z-index: 3;
     }
     input.form-control {
       left: 15px;
     }
     &.is-focused,
     &:not(.is-empty) {
       label.control-label {
         background: $component-active-color;
         color: $brand-primary;
         font-size: $mdb-input-font-size-base;
         left: 10px;
         padding: 3px 5px;
         top: -25px;
         z-index: 4;
       }
       input.form-control {
         border: 1px solid $input-border;
       }
       .input-group {
         .control-label {
           background: $component-active-color;
           color: $brand-primary;
           font-size: $mdb-input-font-size-base;
           z-index: 4;
         }
       }
     }
     &.is-focused.has-error {
       label.control-label {
         color: $state-danger-text;
       }
       .form-control {
         background-image: none;
         border: 1px solid $state-danger-text;
       }
     }
     .input-group {
       label.control-label {
         background: $component-active-color;
         left: 45px;
         line-height: 1;
         padding: 3px 5px;
         z-index: 4;
       }
       input.form-control {
         background: $component-active-color;
         left: auto;
         padding: 3px 5px;
       }
       .help-block {
         display: none;
       }
     }
   }
   .help-support,
   .help-block {
     color: $mdb-input-placeholder-color;
     font-size: $mdb-input-font-size-base;
   }
   .textarea {
     border: 1px solid $input-border;
     font-size: $mdb-input-font-size-base;
     padding: 10px;
     &.small-textarea {
       height: 65px;
       width: 284px;
     }
     &.large-textarea {
       height: 110px;
       width: 484px;
     }
     &.responsive-textarea {
       height: 65px;
       width: 100%;
     }
   }
   .radio {
     label {
       padding: 3px 0 0 25px;
     }
     span {
       left: 0;
     }
     .circle {
       background-color: transparent;
       border: 1px solid $input-border;
       height: 18px;
       width: 18px;
       animation: none;
     }
     .check {
       border: 1px solid $input-border;
       height: 14px;
       left: 2px;
       top: 4px;
       width: 14px;
       &:after {
         display: none;
       }
     }
     input[type=radio]:checked ~ .check {
       background-color: $input-border-focus;
       transform: scale3d(0.60, 0.60, 1);
     }
     input[type=radio]:checked ~ .circle {
       border: 1px solid $input-border-focus;
     }
     input[type=radio][disabled] ~ .circle {
       background-color: $disabled-gray;
       border: 1px solid $input-border;
       opacity: 0.6;
     }
     input[type=radio][disabled][checked] ~ .check {
       background-color: $input-border;
       opacity: 0.76;
     }
   }

   .checkbox {
     .checkbox-material {
       display: inline-block;
       height: 17px;
       top: 0;
       .check {
         border: 1px solid $input-border;
         height: 17px;
         width: 17px;
         &:before {
           box-shadow: none;
           animation: none !important;
         }
       }
       &:before { // this CSS is required for hidding the material design of device touch effects
         display: none;
       }
     }
     input[type=checkbox]:checked + .checkbox-material {
       background-color: $input-border-focus;
       .check {
         border: 1px solid $input-border-focus;
         height: 17px;
         width: 17px;
         &:before {
           content: "";
           position: absolute;
           left: -1px;
           top: 6px;
           width: 5px;
           height: 9px;
           border: solid $component-active-color;
           border-width: 0 2px 2px 0;
           transform: rotate(45deg);
         }
       }
     }
     input[type=checkbox][disabled] + .checkbox-material,
     input[type=checkbox]:checked[disabled] + .checkbox-material {
       background-color: $disabled-gray;
       .check {
         border: 1px solid $input-border;
         &:before {
           box-shadow: none !important;
           border: solid $input-border;
           border-width: 0 2px 2px 0;
         }
       }
     }
   }

   // default select dropdown styles for single and multi-line dropdowns
   select.form-control {
     background-image: none;
     border: 1px solid $input-border;
     color: $gray-base;
     &.small-dropdown {
       width: 284px;
     }
     &.large-dropdown {
       width: 484px;
     }
     &.responsive-dropdown {
       width: 100%;
     }
     &[disabled] {
       border: 1px solid $input-border;
       opacity: 0.5;
     }
   }
   // this css for single dropdown arrow pointer style
   .custom-select-dropdown {
     position: relative;
     select.form-control {
       height: 40px;
       padding: 10px 25px 10px 15px;
       position: relative;
       -webkit-appearance: none;
       -moz-appearance: none;
     }
     select::-ms-expand {
       display: none;
     }
     &:before {
       content: "";
       position: absolute;
       right: 10px;
       top: 15px;
       width: 8px;
       height: 8px;
       border: solid $navbar-inverse-link-disabled-color;
       border-width: 0 1px 1px 0;
       transform: rotate(45deg);
     }
   }
 }

 .table {
   &.table-striped {
     thead {
       tr {
         background-color: $disabled-gray;
         th {
           border-bottom: 2px solid $input-border;
         }
       }
     }
     tr {
       td {
         border-top: 1px solid $input-border;
       }
     }
   }
 }

 .jumbotron {
   &.jumbotron_homepage {
     align-items: center;
     -webkit-align-items:center;
     display: flex;
     display: -webkit-flex;
     flex-wrap: wrap;
     -webkit-flex-wrap: wrap;
     justify-content: center;
     -webkit-justify-content:center;
     position: relative;
     height: 400px;
     z-index: 0;
     img { // This styles are required for place Background image of Banner Container
       position: absolute;
       height: 100%;
       top: 0;
       width: 100%;
       z-index: -1;
     }
     &.transparent-text { // If dark background Banner comes need to apply this white text
       color: $body-bg;
     }
     h1 {
       font-size: $font-size-h1;
     }
     h2 {
       font-family: $font-family-sans-serif;
       font-weight: normal;
     }
   }
 }

 /* .btn {
   outline: none;
   text-decoration: none;
   border: none;
   font-weight: bold;
   text-transform: uppercase;

   &:not(.btn-raised).btn-primary {
     color:$btn-default-color;
   }

   &:active,
   &.active {
     @include box-shadow(none);
   }

   &.btn-lg {
     @include button-size(10px, 20px, $font-size-h6, 1.5, 0);
   }

   &.btn-sm {
     @include button-size(4px, 10px, $font-size-small, 1.5, 0);
   }

   &:focus:not(.btn-raised):not(.btn-link) {
     background-color: $btn-default-bg;
   }

   &.disabled {
     @include button-variant($btn-default-color, $disabled-gray, $btn-default-color);

     &:hover:not(.btn-raised):not(.btn-link) {
       @include box-shadow(none);
       background-color:$disabled-gray;
     }
   }


   &.btn-primary,
   &.btn-primary.active {
     &:active {
       $border: #006275;
       @include button-variant($btn-default-color, $navbar-default-link-active-color, $border);
       @include box-shadow(none);
     }

     &:hover:not(.btn-raised):not(.btn-link):not(.active):not(:active) {
       @include box-shadow(inset -2px -2px 0px 0px rgba(0,136,163,1));
       background-color:$btn-default-bg;
     }
   }

   &.btn-outline {
     border: 1px solid;
     @include button-variant($btn-default-bg, transparent, $btn-default-bg);

     &.active,
     &:active {
         color:$btn-default-color;
         background-color:$navbar-default-link-active-color;
         border-color:$navbar-default-link-active-color;
     }

     &:focus {
         color:$btn-default-color;
     }

     &:hover:not(.btn-raised):not(.btn-link) {
       color:$btn-default-color;
       background-color: $btn-default-bg;
       border-color: transparent;
       @include box-shadow(inset -1px -1px 0px 0px rgba(0,136,163,1));
     }

     &.disabled {
       border: 1px solid;
       @include button-variant($disabled-gray, $btn-default-color, $disabled-gray);
     }
   }

   &.btn-preview {
    border: 1px solid;
    @include button-variant($input-border, $btn-default-color, $input-border);

    &:hover:not(.btn-raised):not(.btn-link),
    &:focus:not(.btn-raised):not(.btn-link),
    &:active {
      color:$btn-default-color;
      background-color: $input-border;
      border-color:$input-border;
    }

    .btn-text {
       vertical-align:middle;
    }
  }

  &.btn-transparent {
    border: 1px solid;
    @include button-variant($btn-default-color, transparent, $btn-default-color);

    &:hover:not(.btn-raised):not(.btn-link),
    &:focus:not(.btn-raised):not(.btn-link) {
      background-color:$btn-default-color;
      color:$gray-light;
      border-color:$gray-light;
    }
  }
 } */

 // --* Loading animation styles: Starts *--//
 .circle-spinner {
   display: flex;
   display: -webkit-flex;
   justify-content: center;
   -webkit-justify-content:center;
   align-items: center;
   -webkit-align-items:center;
   height: 100%;
   width: 100%;
 }
 .loader {
     height: 50px;
     margin: 0px auto;
     position: relative;
     width: 50px;
 }
 .circle {
   @include circleProperties(14px, circular_rotate, 1.04s, infinite, linear);
   position:absolute;
   background-color:$input-border;
   width:10px;
   height:10px;
 }
 .circle-1 {
   @include loaderAnimation(0.39s);
     left: 0;
     top: 20px;
 }
 .circle-2 {
   @include loaderAnimation(0.52s);
     left: 6px;
     top: 6px;
 }
 .circle-3 {
   @include loaderAnimation(0.65s);
     left: 20px;
     top: 0;
 }
 .circle-4 {
   @include loaderAnimation(0.78s);
     right: 6px;
     top: 6px;
 }
 .circle-5 {
   @include loaderAnimation(0.91s);
     right: 0;
     top: 20px;
 }
 .circle-6 {
   @include loaderAnimation(1.04s);
     bottom: 6px;
     right: 6px;
 }
 .circle-7 {
   @include loaderAnimation(1.17s);
     bottom: 0;
     left: 20px;
 }
 .circle-8 {
   @include loaderAnimation(1.3s);
     bottom: 6px;
     left: 6px;
 }
 // --* Loading animation styles: Ends *--//

 // --* Dialog modal styles: Starts *--//
 .modal {
   .modal-dialog {
     left: 50%;
     top: 50%;
     max-height: calc(100% - 100px);
     max-width: 85%;
     margin: 0;
     transform: translate(-50%, -50%) !important;

     .modal-content {
       border: 0 none;
       box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
       .modal-header {
         background-color: $navbar-default-link-active-color;
         padding: 15px 30px;
         position: relative;
         .modal-title {
           color: $body-bg;
           font-size: $carousel-control-font-size;
           font-family: $font-family-sans-serif;
           padding-right: 20px;
         }
         .close {
           color: $body-bg;
           margin: 0;
           opacity: 1;
           position: absolute;
           right: 20px;
           top: 12px;
           text-shadow: none;
           font-size: 30px;
         }
       }
     }
     .modal-body {
       padding: 40px 30px 30px;
     }
     .form-group {
       margin: 0 0 15px;
       padding: 0;
       .form-control {
         font-size: $font-size-base;
       }
     }
     .button-container {
       text-align: center;
       margin: 0;
       .btn {
         margin: 10px 5px 0;
       }
       .btn-outline {
         line-height: 1.4;
       }
     }
   }
 }
 // --* Dialog modal styles: Ends *--//

 // --* Custom progress bar styles: Starts *--//
 .custom-progress {
   height: 30px;
   min-width: 225px;
   margin-bottom: 10px;
   position: relative;
   .progress-label,
   .progress-count {
     display: inline-block;
     left: 10px;
     position: absolute;
     top: 6px;
   }
   .progress-count {
     left: auto;
     right: 10px;
   }
   .progress-bar {
     background-color: $light-turquoise;
   }
 }
 // --* Custom progress bar styles: Ends *--//

 .popover {
  color: #000;
}
.themed-button {
  height: 46px;
  font-weight: 600;
  font-size: 16px !important;
}
.form-label {
  font-size: 16px;
  vertical-align: middle;
}

[class^="material-icons"].md-18 { font-size: 18px; width: 30px; }
[class^="material-icons"].md-30 { font-size: 30px; width: 45px; }
[class^="material-icons"].md-36 { font-size: 36px; width: 50px; }
[class^="material-icons"].md-48 { font-size: 48px; width: 65px;}

button.mat-primary{
  background-color: #007a96 !important;
}

.mat-dialog-title {
  text-align: center !important;
}

.secondary-button {
  border: 1px solid #007a96 !important;
  color: #007a96;
}
.cstm-dialog-title {
  font-size: 16px;
}

.cstm-dialog-description{
  font-size: 14px;
}

button {
  border-radius: 4px;
}

a:hover,
a:focus {
  text-decoration: underline;
}
