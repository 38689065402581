//== Variables
//
// This file gets imported in core (before any other) styles.
$brand-tandf:           #10147e;
$disabled-gray:         #e6e6e6;
$light-turquoise:       #b9e4ec;
$lime-green:            #dddb00;
$body-grey:             #e7e7e7;
$light-grey:            #ccc;

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

//== Bootstrap Variables
//
//## @see: http://getbootstrap.com/customize/#less-variables

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee
$gray-lightest:          lighten($gray-base, 97%) !default;   // #f7f7f7

// COGENT

$dark-grey: #4d4d4d;
$footer-grey: #676767;

// COGENT



$brand-primary:         #007a96 !default;
$brand-success:         #4BC09A !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color:            $gray-base !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       'Droid Serif', Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          13px !default;
$font-size-large:         ceil(($font-size-base * 1.15)) !default; // ~15px
$font-size-small:         ceil(($font-size-base * 0.76)) !default; // ~10px
$font-size-h6:            ceil(($font-size-base * 0.92)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.

//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.

// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/bootstrap/") !default;

//== Components
//
$line-height-base:          1.538461539 !default; // 20/13
$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;
//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
//== Tables
//

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal !default;

$btn-default-color:              #fff !default;
$btn-default-bg:                 #0ac !default;
$btn-default-border:             #ccc !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-link-disabled-color:        $gray-light !default;

$border-radius-base:        0px !default;
$border-radius-large:       0px !default;
$border-radius-small:       0px !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;


//** `<input>` background color
$input-bg:                       #fff !default;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter !default;

//** Text color for `<input>`s
$input-color:                    $gray-base !default;
//** `<input>` border color
$input-border:                   #928e8e !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius

//** Border color for inputs on focus
$input-border-focus:             #0ac !default;

//** Placeholder text color
$input-color-placeholder:        #928e8e !default;

//**accessible Placeholder text color
$accessible-color-placeholder:        #000000 !default;

//**accessible label color
$accessible-color-label:        #666666 !default;

//** input error color
$input-color-danger:             #cf1e0a !default;

//** accessible blue color
$teal-blue:             #007a96 !default;

//** accessible blue color
$onahau-blue:            #cceaf0 !default;

//** accessible grey color
$identity-grey:           rgba(247,247,247,255);


//** Default `.form-control` height

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  320px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  1024px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1280px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

// breakpoints
$xsmall-breakpoint: 320px;
$msmall-breakpoint: 600px;
$small-breakpoint: 840px;
$medium-breakpoint: 1280px;
$large-breakpoint: 1440px;
$xlarge-breakpoint: 1600px;
$xxlarge-breakpoint: 1920px;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
//** Point at which the navbar begins collapsing.
//##

// Navbar links
$navbar-default-link-active-color:         #0088A3 !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-danger-text:              #FF0000 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;

$badge-color:                 #fff !default;
//== Breadcrumbs
//
//##

//== Carousel
//
//##

$carousel-control-font-size:                  20px !default;
//== Close

$close-color:                 #000 !default;

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------


$black: #000000; $rgb-black: "0,0,0" !default;
$white: #ffffff; $rgb-white: "255,255,255" !default;

// Typography elements
$mdb-font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif !default;
$mdb-text-color-primary: unquote("rgba(#{$rgb-black}, 0.87)") !default;
$mdb-text-color-primary-hex: $black !default; // for contrast function in inverse
$icon-color: rgba(0,0,0,0.5) !default;
$mdb-label-color: unquote("rgba(#{$rgb-black}, 0.26)") !default;
$mdb-label-color-toggle-focus: unquote("rgba(#{$rgb-black}, .54)") !default;

// import bs variables for less, last declared wins.

$mdb-input-font-size-base: 12px !default;
$mdb-input-font-size-large: ceil(($font-size-base * 1.25)) !default; // ~20px
$mdb-input-font-size-small: ceil(($font-size-base * 0.75)) !default; // ~12px


//---
// Converted bs variables

// Bootstrap brand color customization
$brand-primary: $teal !default;
$brand-success: $green !default;
$brand-danger: $red !default;
$brand-warning: $deep-orange !default;
$brand-info: $light-blue !default;

