@font-face {
  font-family: 'tf-icon-font';
  src:  url('../fonts/icomoon.eot') format('embedded-opentype'), /* IE9 */
    url('../fonts/icomoon.ttf') format('truetype'); /* Safari, Chrome, Firefox, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-block;
  font-family: 'tf-icon-font', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: "\e917";
}

.icon-calendar:before {
  content: "\e90e";
}
.icon-user:before {
  content: "\e91f";
}
.icon-search:before {
  content: "\e920";
}
.icon-down:before {
  content: "\e921";
}
.icon-up:before {
  content: "\e922";
}
.icon-left:before {
  content: "\e923";
}
.icon-right:before {
  content: "\e924";
}
.icon-check:before {
  content: "\e925";
}
.icon-cancel:before {
  content: "\e926";
}
.icon-check-circle:before {
  content: "\e927";
}
.icon-cancel-circle:before {
  content: "\e928";
}
.icon-info:before {
  content: "\e929";
}
.icon-error:before {
  content: "\e92a";
}
.icon-image:before {
  content: "\e92b";
}
.icon-external-link:before {
  content: "\e92c";
}
.icon-dashboard:before {
  content: "\e92d";
}
.icon-entitlements:before {
  content: "\e92e";
}
.icon-acquisition-history:before {
  content: "\e92f";
}
.icon-news-recommendations:before {
  content: "\e930";
}
.icon-reporting:before {
  content: "\e931";
}
.icon-settings:before {
  content: "\e932";
}
.icon-notifications:before {
  content: "\e933";
}
.icon-institution:before {
  content: "\e934";
}
.icon-help-faq:before {
  content: "\e935";
}
.icon-logout:before {
  content: "\e936";
}
.icon-filter:before {
  content: "\e937";
}
.icon-sort:before {
  content: "\e938";
}
.icon-arrow-down:before {
  content: "\e939";
}
.icon-arrow-up:before {
  content: "\e93a";
}
.icon-arrow-left:before {
  content: "\e93b";
}
.icon-arrow-right:before {
  content: "\e93c";
}
.icon-link:before {
  content: "\e93d";
}

.icon-facebook:before {
  content: "\e943";
}
.icon-twitter:before {
  content: "\e944";
}
.icon-youtube:before {
  content: "\e945";
}
.icon-pinterest:before {
  content: "\e903";
}
.icon-ip:before {
  content: "\e904";
}
.icon-manage-institution:before {
  content: "\e905";
}
.icon-manage-users:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-pages:before {
  content: "\e908";
}
.icon-pointer:before {
  content: "\e909";
}
.icon-open-access:before {
  content: "\e90a";
}
.icon-email:before {
  content: "\e90f";
}
.icon-password:before {
  content: "\e911";
}
.icon-citation:before {
  content: "\e910";
}
.icon-delete:before {
  content: "\e913";
}
.icon-processing:before {
  content: "\e912";
}
.icon-product-bundle:before {
  content: "\e90d";
}
.icon-marc-records1:before {
  content: "\e914";
}
.icon-data-requests:before {
  content: "\e915";
}
.icon-licenses:before{
  content: "\e90c";
}
.icon-Organisations:before {
  content: "\e916";
}
.icon-linkedin:before {
  content: "\e946";
}
.icon-instagram:before {
  content: "\e947";
}
.icon-eye:before {
  content: "\e941";
}
.icon-eye-blocked:before {
  content: "\e942";
}
.icon-inr:before {
  content: "\e940";
}
.icon-rupee:before {
  content: "\e940";
}